import { BaseDto } from './base-dto';

export class Login extends BaseDto {
    public UserName!: string;
    public Pwd!: string;


    public Success!: boolean;
    public chg_pwd!: boolean;
    public Message!: string;
    public Token!: string;
    public Name!: string;
    public sercret_opt!: string;
    public config_otp!: boolean;
    public doble_factor!: boolean;

}
